import axios from 'axios'
import qs from 'querystring'
import SparkMD5 from 'spark-md5'

import { type PrincipalDto } from '../../shared/store/session.store'

export const LoginService = {
  async login(
    username: string,
    password: string,
  ): Promise<{ principal: PrincipalDto; key: string }> {
    const principal = {
      username,
      password: SparkMD5.hash(password),
    }
    const { data } = await axios.post(
      '/api/sessions',
      qs.stringify(principal),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    )
    return data
  },
}
/*
import axios from "axios";
import SparkMD5 from "spark-md5";

export const LoginService = {
  async login(username: string, password: string) {
    let principal = {
      name: username,
      passwordHash: SparkMD5.hash(password)
    };
    const { data } = await axios.post("/api/sessions", {
      principal: principal
    });
    return data;
  }
};*/
