import { createStore, type GettersObj } from '../../utils/createStore'
import { ErrorService } from '../service/errorService'
import { WarningBannerService } from './../service/warningBanner.service'

export type GettersWarningBanner = GettersObj<typeof mapGettersWarningBanner>

export class WarningBannerState {
  message = ''
  loaded = false
}

export const {
  warningBanner,
  commit: commitWarningBanner,
  useGetter: useGetterWarningBanner,
  useState: useStateWarningBanner,
  mapGetters: mapGettersWarningBanner,
  mapState: mapStateWarningBanner,
  dispatch: dispatchWarningBanner,
} = createStore({
  namespaced: true,
  moduleName: 'warningBanner',
  initState: new WarningBannerState(),
  actions: {
    async loadWarningBanner({ commit }) {
      try {
        const data = await WarningBannerService.getWarningBanner()
        commit('SET_MESSAGE', data.message)
      } catch (e) {
        ErrorService.handleError(e, false)
      }
    },
  },

  mutations: {
    SET_MESSAGE(state, message: string) {
      state.message = message
      state.loaded = true
    },
  },

  getters: {
    getWarningBannerIsLoaded: (state) => {
      return state.loaded
    },
  },
})
