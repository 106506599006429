<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center" class="row-margin">
      <v-col cols="12" sm="12" md="3">
        <v-card class="card-min-size">
          <v-card dark color="#4CB9DC" class="card-header">
            <h4>{{ $tc('LOGIN.WELCOME') }}</h4></v-card
          >
          <v-card-text class="card-text-margin">
            <v-form ref="form">
              {{ $tc('LOGIN.INPUT') }}

              <v-col md="12">
                <v-text-field
                  id="login-input"
                  v-model="username"
                  :label="$tc('LOGIN.USERNAME')"
                  prepend-icon="face"
                  :rules="textRule"
                />
                <v-text-field
                  id="password-input"
                  v-model="password"
                  :label="$tc('LOGIN.PASSWORD')"
                  prepend-icon="lock_outline"
                  type="password"
                  :rules="textRule"
                />
              </v-col>
            </v-form>
          </v-card-text>

          <v-divider />

          <v-card-actions class="action-card">
            <v-btn text color="success" @click="loginAction()">
              <v-icon left color="success">vpn_key</v-icon
              >{{ $tc('LOGIN.LOGIN') }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { dispatchLogin } from './login.store'

export default defineComponent({
  data() {
    return {
      textRule: [(v: string): true | string => !!v || ''],
      username: '',
      password: '',
    }
  },

  created() {
    window.addEventListener('keydown', async (e) => {
      if (e.key === 'Enter') {
        await this.loginAction()
      }
    })
  },

  methods: {
    async loginAction() {
      if ((this.$refs.form as any)?.validate()) {
        await dispatchLogin('login', {
          username: this.username,
          password: this.password,
        })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.card-header {
  margin: -20px 15px 0;
  border-radius: 3px;
  padding: 15px;
  background: #4cb9dc none;
  color: white;
  font-weight: 500;
  position: absolute;
  width: calc(100% - 30px);

  h4 {
    text-align: center;
    margin: 10px 0 10px 0;
  }
}

.card-text-margin {
  padding-top: 75px;
}

.action-card {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 10px 0 10px 0;
}

.centre-title {
  text-align: center;
}

.row-margin {
  margin: 0px;
}
</style>
