import Vue from 'vue'
import Vuex, { type Store } from 'vuex'

import { ads, type AdsState, type GettersAds } from './pages/ads/ads.store'
import {
  type DashboardMatomoState,
  type GettersDashboardMatomo,
  matomoDashboard,
} from './pages/analytics/dashboardAnalytics.store'
import {
  applicationCfg,
  type ApplicationCfgState,
  type GettersApplicationCfg,
} from './pages/applicationCfg/store/applicationCfg.store'
import {
  attendee,
  type AttendeeState,
  type GettersAttendee,
} from './pages/attendee/attendee.store'
import {
  attendeeWhiteList,
  type AttendeeWhiteListState,
  type GettersAttendeeWhiteList,
} from './pages/attendee-white-list/attendeeWhiteList.store'
import {
  dashboard,
  type DashboardState,
  type GettersDashboard,
} from './pages/dashboard/dashboard.store'
import {
  festival,
  type FestivalState,
  type GettersFestival,
} from './pages/festival/festival.store'
import {
  gallery,
  type GalleryState,
  type GettersGallery,
} from './pages/gallery/gallery.store'
import {
  type GettersGuestGenres,
  guestGenres,
  type GuestGenresState,
} from './pages/guests/store/guestgenres.store'
import {
  type GettersGuests,
  guests,
  type GuestsState,
} from './pages/guests/store/guests.store'
import {
  type GettersGuestType,
  type GuestTypeState,
} from './pages/guestTypes/guestType.store'
import { guestType } from './pages/guestTypes/guestType.store'
import {
  type GettersImports,
  imports,
  type ImportsState,
} from './pages/import/store/import.store'
import {
  type GettersInfos,
  infos,
  type InfosState,
} from './pages/infos/infos.store'
import {
  type GettersLogin,
  login,
  type LoginState,
} from './pages/login/login.store'
import {
  type GettersMap,
  map,
  type MapState,
} from './pages/map/store/map.store'
import {
  type GettersMaps,
  maps,
  type MapsState,
} from './pages/map/store/maps.store'
import {
  type GettersMenuEntries,
  menuEntries,
  type MenuEntriesState,
} from './pages/menuEntries/menuEntries.store'
import {
  type GettersPageSetup,
  pageSetup,
  type PageSetupState,
} from './pages/menuEntries/pageSetup/pageSetup.store'
import {
  type GettersNotifications,
  notifications,
  type NotificationsState,
} from './pages/notifications/notifications.store'
import {
  type GettersNotificationsTopics,
  notificationsTopics,
  type NotificationsTopicsState,
} from './pages/notificationsTopics/notificationsTopics.store'
import {
  type GettersOrphaned,
  orphaned,
  type OrphanedState,
} from './pages/orphaned/orphaned.store'
import {
  type GettersProgEvent,
  progEvent,
  type ProgEventState,
} from './pages/progEvent/progEvent.store'
import {
  type GettersPrograms,
  programs,
  type ProgramsState,
} from './pages/programs/programs.store'
import {
  type GettersPublications,
  publications,
  type PublicationsState,
} from './pages/publications/publications.store'
import {
  type GettersResetApp,
  resetApp,
  type ResetAppState,
} from './pages/resetApp/resetApp.store'
import {
  type GettersScenes,
  scenes,
  type ScenesState,
} from './pages/scenes/scenes.store'
import {
  type GettersSponsors,
  type SponsorsState,
} from './pages/sponsors/sponsors.store'
import { sponsors } from './pages/sponsors/sponsors.store'
import {
  type GettersSponsorsCategories,
  sponsorsCategories,
  type SponsorsCategoriesState,
} from './pages/sponsors/sponsorsCategories/sponsorsCategories.store'
import {
  type GettersStaticCfgs,
  staticCfgs,
  type StaticCfgsState,
} from './pages/staticCfg/store/staticCfgs.store'
import {
  type GettersStaticConfig,
  staticConfig,
  type StaticConfigState,
} from './pages/staticCfg/store/staticConfig.store'
import {
  type GettersStoresInformations,
  type StoreInformationsState,
  storesInformations,
} from './pages/storesInformations/storesInformations.store'
import {
  type GettersUsers,
  users,
  type UsersState,
} from './pages/users/users.store'
import {
  type GettersSnackbar,
  snackbar,
  type SnackbarState,
} from './shared/snackbar/snackbar.store'
import {
  appBar,
  type AppBarState,
  type GettersAppBar,
} from './shared/store/app-bar.store'
import {
  appConfig,
  type AppConfigState,
  type GettersAppConfig,
} from './shared/store/app-config.store'
import {
  appData,
  type AppDataState,
  type GettersAppData,
} from './shared/store/app-data.store'
import {
  type GettersIsMobile,
  isMobile,
  type IsMobileState,
} from './shared/store/isMobile.store'
import {
  type GettersScrollPosition,
  scrollPosition,
  type ScrollPositionState,
} from './shared/store/scrollPosition.store'
import {
  type GettersSession,
  session,
  type SessionState,
} from './shared/store/session.store'
import {
  type GettersWarningBanner,
  warningBanner,
  type WarningBannerState,
} from './shared/store/warningBanner.store'

export type RootState = {
  ads: AdsState
  appData: AppDataState
  applicationCfg: ApplicationCfgState
  attendee: AttendeeState
  attendeeWhiteList: AttendeeWhiteListState
  dashboard: DashboardState
  festival: FestivalState
  gallery: GalleryState
  guestGenres: GuestGenresState
  guests: GuestsState
  guestType: GuestTypeState
  imports: ImportsState
  infos: InfosState
  login: LoginState
  map: MapState
  maps: MapsState
  matomoDashboard: DashboardMatomoState
  menuEntries: MenuEntriesState
  notifications: NotificationsState
  notificationsTopics: NotificationsTopicsState
  orphaned: OrphanedState
  pageSetup: PageSetupState
  progEvent: ProgEventState
  programs: ProgramsState
  publications: PublicationsState
  resetApp: ResetAppState
  scenes: ScenesState
  scrollPosition: ScrollPositionState
  snackbar: SnackbarState
  sponsors: SponsorsState
  sponsorsCategories: SponsorsCategoriesState
  staticCfgs: StaticCfgsState
  staticConfig: StaticConfigState
  storesInformations: StoreInformationsState
  users: UsersState
  warningBanner: WarningBannerState
  //Global modules
  appBar: AppBarState
  appConfig: AppConfigState
  isMobile: IsMobileState
  session: SessionState
}

export type UnionToIntersection<U> = (
  U extends any ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never

export type FlatObj<T extends Record<string, Record<string, any>>> = {
  [K1 in keyof T]: {
    [K2 in keyof T[K1] as `${K1 & string}/${K2 & string}`]: T[K1][K2]
  }
}[keyof T]

export type RootGetters = UnionToIntersection<
  FlatObj<{
    ads: GettersAds
    appData: GettersAppData
    applicationCfg: GettersApplicationCfg
    attendee: GettersAttendee
    attendeeWhiteList: GettersAttendeeWhiteList
    dashboard: GettersDashboard
    festival: GettersFestival
    gallery: GettersGallery
    guestGenres: GettersGuestGenres
    guests: GettersGuests
    guestType: GettersGuestType
    imports: GettersImports
    infos: GettersInfos
    isMobile: GettersIsMobile
    login: GettersLogin
    map: GettersMap
    maps: GettersMaps
    matomoDashboard: GettersDashboardMatomo
    menuEntries: GettersMenuEntries
    notifications: GettersNotifications
    notificationsTopics: GettersNotificationsTopics
    orphaned: GettersOrphaned
    pageSetup: GettersPageSetup
    progEvent: GettersProgEvent
    programs: GettersPrograms
    publications: GettersPublications
    resetApp: GettersResetApp
    scenes: GettersScenes
    scrollPosition: GettersScrollPosition
    snackbar: GettersSnackbar
    sponsors: GettersSponsors
    sponsorsCategories: GettersSponsorsCategories
    staticCfgs: GettersStaticCfgs
    staticConfig: GettersStaticConfig
    storesInformations: GettersStoresInformations
    users: GettersUsers
    warningBanner: GettersWarningBanner
    // DONT ADD GLOBAL MODULES HERE !!
    // GettersSession,  GettersAppConfig,  GettersAppBar, GetterqqIsMobile...
  }>
> &
  GettersSession &
  GettersAppConfig &
  GettersAppBar &
  GettersIsMobile

Vue.use(Vuex)

const store = new Vuex.Store<RootState>({
  modules: {
    ads,
    appData,
    applicationCfg,
    attendee,
    attendeeWhiteList,
    dashboard,
    festival,
    gallery,
    guestGenres,
    guests,
    guestType,
    imports,
    infos,
    login,
    map,
    maps,
    matomoDashboard,
    menuEntries,
    notifications,
    notificationsTopics,
    orphaned,
    pageSetup,
    progEvent,
    programs,
    publications,
    resetApp,
    scenes,
    scrollPosition,
    snackbar,
    sponsors,
    sponsorsCategories,
    staticCfgs,
    staticConfig,
    storesInformations,
    users,
    warningBanner,
    //Global modules
    appBar,
    appConfig,
    isMobile,
    session,
  },
})

if (import.meta.hot) {
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  import.meta.hot.accept(['./pages/login/login.store'], async () => {
    store.hotUpdate({
      modules: {
        login: (await import('./pages/login/login.store')).login,
        resetApp: (await import('./pages/resetApp/resetApp.store')).resetApp,
        orphaned: (await import('./pages/orphaned/orphaned.store')).orphaned,
        applicationCfg: (
          await import('./pages/applicationCfg/store/applicationCfg.store')
        ).applicationCfg,
        ads: (await import('./pages/ads/ads.store')).ads,
        appConfig: (await import('./shared/store/app-config.store')).appConfig,
        addData: (await import('./shared/store/app-data.store')).appData,
        publications: (await import('./pages/publications/publications.store'))
          .publications,
        users: (await import('./pages/users/users.store')).users,
        notifications: (
          await import('./pages/notifications/notifications.store')
        ).notifications,
        notificationsTopics: (
          await import('./pages/notificationsTopics/notificationsTopics.store')
        ).notificationsTopics,
        menuEntries: (await import('./pages/menuEntries/menuEntries.store'))
          .menuEntries,
        matomoDashboard: (
          await import('./pages/analytics/dashboardAnalytics.store')
        ).matomoDashboard,
        attendeeWhiteList: (
          await import('./pages/attendee-white-list/attendeeWhiteList.store')
        ).attendeeWhiteList,
        storesInformations: (
          await import('./pages/storesInformations/storesInformations.store')
        ).storesInformations,
        festival: (await import('./pages/festival/festival.store')).festival,
        infos: (await import('./pages/infos/infos.store')).infos,
        sponsors: (await import('./pages/sponsors/sponsors.store')).sponsors,
        sponsorsCategories: (
          await import(
            './pages/sponsors/sponsorsCategories/sponsorsCategories.store'
          )
        ).sponsorsCategories,
        attendee: (await import('./pages/attendee/attendee.store')).attendee,
        guests: (await import('./pages/guests/store/guests.store')).guests,
        scenes: (await import('./pages/scenes/scenes.store')).scenes,
        programs: (await import('./pages/programs/programs.store')).programs,
        progEvent: (await import('./pages/progEvent/progEvent.store'))
          .progEvent,
        appBar: (await import('./shared/store/app-bar.store')).appBar,
        warningBanner: (await import('./shared/store/warningBanner.store'))
          .warningBanner,
        dashboard: (await import('./pages/dashboard/dashboard.store'))
          .dashboard,
        map: (await import('./pages/map/store/map.store')).map,
        isMobile: (await import('./shared/store/isMobile.store')).isMobile,
        gallery: (await import('./pages/gallery/gallery.store')).gallery,
        scrollPosition: (await import('./shared/store/scrollPosition.store'))
          .scrollPosition,
        pageSetup: (
          await import('./pages/menuEntries/pageSetup/pageSetup.store')
        ).pageSetup,
        guestType: (await import('./pages/guestTypes/guestType.store'))
          .guestType,
        imports: (await import('./pages/import/store/import.store')).imports,
      },
    })
  })
}

export default store as Omit<
  Store<
    RootState & SessionState & AppConfigState & AppBarState & IsMobileState
  >,
  'getters'
> & { getters: unknown }
