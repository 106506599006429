import axios from 'axios'

import { type PrincipalDto } from '../store/session.store'

export const SessionService = {
  async getSession(): Promise<{ principal: PrincipalDto; key: string }> {
    const { data } = await axios.get(`/api/session/current`)
    return data
  },

  async logout(): Promise<void> {
    await axios.post(`/api/logout`)
  },
}
