import axios, { type AxiosError } from 'axios'

// import {
//   dispatchAppConfig,
//   useAppConfigGetter
// } from "./shared/store/app-config.store";
import store, { type RootGetters } from './store'

axios.interceptors.response.use(
  async function (response) {
    const fe_version: string =
      (response.headers as Record<string, string | undefined>)['fe-version'] ??
      'default'
    if (
      fe_version !== localStorage.getItem('fe-version') &&
      response.config.method === 'get'
    ) {
      localStorage.setItem('fe-version', fe_version)
      window.location.reload() // For new version, simply reload on any get
    }
    return Promise.resolve(response)
  },
  async function (error: AxiosError) {
    if (error.response?.status === 401) {
      store.commit('RESET')
    } else {
      return Promise.reject(error)
    }
  },
)

axios.interceptors.request.use(async function (config) {
  const isMutationMethod = ['post', 'put', 'patch', 'delete'].includes(
    config.method ?? '',
  )
  const isExcludedPath = [
    '/api/applicationConfig/markDirty',
    '/api/analytics',
    '/api/users',
    '/api/staticConfig',
    '/api/sessions',
    '/api/notif',
  ].some((path) => config.url?.startsWith(path))

  if (
    isMutationMethod &&
    !isExcludedPath &&
    // FIXME weird bug "can't access lexical variable 'appConfig' before initialization"
    // useAppConfigGetter("isDirty").value &&
    !(store.getters as RootGetters).isDirty
  ) {
    // FIXME idem
    // await dispatchAppConfig("MARK_DIRTY");
    await store.dispatch('MARK_DIRTY')
  }
  return config
})
