import {
  commitSession,
  dispatchSession,
  useGetterSession,
} from '../../shared/store/session.store'
import { dispatchWarningBanner } from '../../shared/store/warningBanner.store'
import { type RootGetters } from '../../store'
import { createStore, type GettersObj } from '../../utils/createStore'
import { dispatchApplicationCfg } from '../applicationCfg/store/applicationCfg.store'
import { dispatchStoresInformations } from '../storesInformations/storesInformations.store'
import { SnackbarService } from './../../shared/snackbar/snackbar.service'
import { LoginService } from './login.service'

export type GettersLogin = GettersObj<typeof mapGettersSession>

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class LoginState {}

function handleError(): void {
  SnackbarService.error('veuillez vérifier votre saisie')
}

export const {
  login,
  commit: commitLogin,
  mapState: mapStateLogin,
  mapGetters: mapGettersSession,
  useGetter: useGetterLogin,
  useState: useStateLogin,
  dispatch: dispatchLogin,
} = createStore({
  namespaced: true,
  moduleName: 'login',
  initState: new LoginState(),
  actions: {
    async login(
      { rootGetters },
      { username, password }: { username: string; password: string },
    ) {
      try {
        const session = await LoginService.login(username, password)
        commitSession('SET_SESSION', session)
        // SessionService.fsIdentify(session.principal);
        await Promise.all([
          dispatchApplicationCfg('loadApplicationCfg'),
          dispatchStoresInformations('loadAppStore'),
          !(rootGetters as RootGetters)[
            'warningBanner/getWarningBannerIsLoaded'
          ]
            ? dispatchWarningBanner('loadWarningBanner')
            : null,
          useGetterSession('isAdmin').value
            ? dispatchSession('CHECK_VERSION')
            : null,
        ])
      } catch (e) {
        handleError()
      }
    },
  },
  mutations: {},
  getters: {},
})
