<template>
  <div style="text-align: right">
    <v-chip
      class="ma-2"
      color="success"
      text-color="white"
      v-if="upToDate && version"
      ><v-avatar left><v-icon>fa-check-circle</v-icon></v-avatar
      >{{ version }}</v-chip
    >
    <a
      target="_blank"
      v-if="!upToDate"
      :href="monitorLink"
      style="cursor: pointer"
    >
      <v-chip class="ma-2" color="warning">
        <v-avatar left><v-icon>fa-circle-exclamation</v-icon></v-avatar>
        Mettre à jour {{ version }}</v-chip
      >
    </a>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import store from '../../store'

const version = computed((): string => store.state.session.version)
const upToDate = computed((): boolean => store.state.session.upToDate)

const env = window.location.host.includes('.chapi.to')
  ? window.location.host.substring(0, window.location.host.indexOf('.chapi.to'))
  : 'itg'
const monitorLink = `https://itg-chapito-monitor.c-t.io/?display=list&search=${env}`
</script>
